<template>
  <div
    v-if="!isSliderInitialized"
    class="slider-main !w-full animate-pulse bg-gray-300"
    :class="height"
  ></div>
  <client-only>
    <swiper-container
      v-show="isSliderInitialized"
      ref="sliderRef"
      class="slider-main !w-full"
      space-between="0"
      v-bind="
        data?.autoplay
          ? {
              'auto-play': true,
              'autoplay-delay': (data?.playDuration ?? 2.5) * 1000,
              'autoplay-disable-on-interaction': false,
              'autoplay-pause-on-mouse-enter': true
            }
          : undefined
      "
      data-testid="slider-main"
      :pagination="data?.showThumbs"
      :navigation="slides?.length > 1"
      init="false"
      :class="height"
    >
      <Slide
        v-for="(slide, index) in slides"
        :key="`slide-main-${slide.id}`"
        :data="slide"
        :index="index"
        :with-counter-button="Boolean(data.showCounter && data.counterButton)"
        :show-thumbs="data.showThumbs"
      />
    </swiper-container>
  </client-only>
</template>

<script lang="ts" setup>
import { register } from 'swiper/element/bundle'
import { ref, onMounted, onServerPrefetch } from 'vue'
import type { Preview } from '@autobid/ui/types/components/Slider'
import type { CollectionName } from '@autobid/ui/composables/useSlides'
import {
  getPreviewSlideQuery,
  getSlideQuery,
  useSlides
} from '@autobid/ui/composables/useSlides'
import type { Button } from '@autobid/ui/types/components/Button'
import { useStrapiLang } from '@autobid/strapi-integration/composable/useStrapiLang'
import { useTheme } from '@autobid/ui/composables/useTheme'
import type { Locales } from '@autobid/ui/types/Locales'
import { useCmsConfig } from '@autobid/strapi-integration/composable/useCmsConfig'
import SliderAutoplayProgress from './SliderAutoplayProgress.vue'
import Slide from './Slide.vue'

register(true)

type Height = 'h300' | 'h420'

type SliderData = {
  autoplay?: boolean
  playDuration?: number
  collectionName: CollectionName
  autoplayProgress?: boolean
  showCounter?: boolean
  counterButton?: Button
  listUrl?: string
  showThumbs?: boolean
  locales?: Locales
  height?: Height
}

interface Props {
  data?: SliderData
  preview?: Preview
}

const props = defineProps<Props>()

const sliderRef = ref()

const height: string = inject('height', '')

const { strapiLang: lang } = useStrapiLang()
const themeName = useTheme()
const { collection: pageCollectionName } = useCmsConfig()
const query = props.preview
  ? getPreviewSlideQuery({
      collectionName: props.data.collectionName,
      ...props.preview,
      themeName
    })
  : getSlideQuery(
      props.data.collectionName,
      pageCollectionName,
      props.data.locales?.languages ?? lang.value,
      lang.value,
      themeName
    )

const {
  data: slides,
  suspense,
  prefetch
} = useSlides({
  collectionName: props.data.collectionName,
  query,
  locale: props.data.locales?.languages,
  app: themeName,
  previewId: props.preview?.id
})

const isSliderInitialized = ref(false)

const initSlider = () => {
  const swiperEl = sliderRef.value

  if (!swiperEl || typeof window === 'undefined') {
    return
  }

  const params = {
    injectStyles: [
      `
       .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal{
          display: flex;
          width: 100%;
          justify-content: flex-end;
        }
        @media (min-width: 1280px){
          .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal{
            justify-content: center;
          }
        }

        @media (max-width: 768px){
          .swiper-pagination-bullet {
            width: 2.125rem !important;
            height: 0.3125rem !important;
          }
        }

      .swiper-pagination-bullet {
          width: 4.5rem;
          height: 0.5rem;
          opacity: 0.45;
          border-radius: 1rem;
          background: white;
      }
      .swiper-pagination-bullet {
          filter: drop-shadow(0 1px 1px #58585a);
          will-change: filter; // fix for Safari bug 
      }
      .swiper-pagination-bullet-active {
        opacity: 1 !important;
        background: white;
      }
      .swiper-button-next,
      .swiper-button-prev{
        color: white;
        font-weight: 800;
        text-shadow: 0px 3px 6px #000000;
        display: none;
      }

      @media (min-width: 1280px){
        .swiper-button-next,
        .swiper-button-prev{
          display: block;
          filter: drop-shadow(0 1px 1px #58585a);
        }
      }

      @media (hover: hover) {
        .swiper-button-prev {
          margin-left: 7px;
        }
        .swiper-button-next {
          margin-right: 7px;
        }
      }

      .swiper-button-next::after,
      .swiper-button-prev::after{
        font-size: 3.25rem;
      }

      .swiper-button-prev.swiper-button-disabled,
      .swiper-button-next.swiper-button-disabled{
        pointer-events: auto;
      } 
      `
    ],
    pagination: {
      clickable: true
    }
  }

  Object.assign(swiperEl, params)

  swiperEl.initialize()
  isSliderInitialized.value = true
}

onMounted(() => {
  initSlider()
})

onServerPrefetch(suspense)

if (process.client) {
  await prefetch()
}

watch(sliderRef, () => {
  initSlider()
})
</script>

<style lang="scss">
.slider {
  span {
    display: block;
    width: auto;
  }

  .content {
    span {
      &[style*='background-color'] {
        background-color: transparent !important; // color is set to `.group` in `useSlides.ts`
      }
    }

    span,
    a {
      & + br {
        display: none;
      }
    }
  }
}
</style>
